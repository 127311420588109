// modal
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background-color: white;
  overflow: hidden;
  width: 50%;
  height: 50%;
  border-radius: 7px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.modal-header {
  padding: 10px 20px;
  background-color: #252525;
  background-image: url(https://res.cloudinary.com/net-ninja/image/upload/v1616238571/Net%20Ninja%20Pro/pro-images/pro-banner_rwboej.png);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  background-color: $submenu-bg-color;
  border: 1px solid $sidebar-color;
  border-radius: 3px;
  padding: 10px;
  button:not(:first-child):not(:last-child) {
    margin-left: 10px;
  }
  button:last-child {
    margin-left: 10px;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal-content {
  padding: 20px;
  overflow-y: auto;
  max-height: 700px;
  color: $brand_content_text;
}

.modal-form {
  .d-flex div.input-field {
    flex-basis: 50%;
    padding: 0 2px;
  }

  // .d-inline-flex div.input-field{
  //   width: 50%;
  // }

  .input-field {
    textarea {
      width: 100%;
      padding: 3px;
    }
  }
  .card.mockup-img {
    border: thin solid #ddd;
    margin: 0 auto;
    img {
      padding: 5px;
      margin: 0 auto;
    }
  }

  label {
    display: block;
    input[type="radio"] {
      margin: 0 5px;
    }
  }
}

.modal-form{
  &.modal-item, &.modal-graphic {
    .input-field{
      margin-bottom: 10px;
    }
  }
}

.modal-close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: $brand_content_bg;
  color: $brand_text;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  &:hover {
    background-color: $brand_heading;
    color: $brand_primary;
    border: thin solid $brand_heading;
  }
}
